:root {
  --hda-black: #1a1919;
  --hda-orange: #d28840;
  --hda-beige: #f9e5c2;

  --hda-orange-active: #a86c33;
  --hda-orange-visited: #db9f66;

  --hda-green: #889f4b;
  --hda-red: #8e2d2c;
}
